import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { easeInOut, motion } from "framer-motion";
import ausalogowhite from "../../assets/ausalogowhite.png";
import ausalogo from "../../assets/ausa_logo.png";
import trafficcone from '../../assets/traffic_cone.png'
import ReactPlayer from "react-player";
// import device from "../../assets/device.png";
import ausadevice from "../../assets/ausa-device.svg"
import spiraltop from "../../assets/spiral-top.png"
import spiralbottom from "../../assets/spiral-bottom.png"
import bg from "../../assets/bg.svg";
import line from "../../assets/line.png";
import ellipse from "../../assets/ellipse.png";
import doctor from "../../assets/doctor.png";
import footerlogo from "../../assets/footerlogo.png";
import prarlogo from "../../assets/prarlogo.png"
import mail from "../../assets/mail.png";
import imgop from "../../assets/imgop.png"
import imgds from "../../assets/imgds.png"

import TextSlider from '../../components/TextSlider'
import IconSlider from '../../components/IconSlider'
import LandingSlider from '../../components/LandingSlider'

import measure from "../../assets/measure.png";
import machine from "../../assets/machine.png";
import measure1 from "../../assets/measure1.svg";
import measure2 from "../../assets/measure-2.svg";
import measure3 from "../../assets/measure3.svg";
import measure4 from "../../assets/measure-4.svg";
import measure5 from "../../assets/measure5.svg";
import measure6 from "../../assets/measure6.svg";
import measurelist from "../../assets/measure-list.svg"
import device from "../../assets/device-1.png";
import ai from "../../assets/ai.svg";
import chatbot from "../../assets/chatbot.svg"
import spiralfull from "../../assets/spiral-full.svg";
import { useMediaQuery } from "react-responsive";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../components/Navbar";

<link rel="stylesheet" href="path/to/aos.css" />;

const TextContainer = styled.div`
  display: grid;
  grid-template-areas:
    "top-left top-right"
    "bottom-left bottom-right";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 4rem;
  margin-top: -1rem;
`;

const Text = styled(motion.div)`
  grid-area: ${({ position }) => position};
  background-color: white;
  padding: 1rem;
  color: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 01rem;
  height: 4.5rem;
  width: 18rem;
  // margin-bottom: 1rem;
  font-weight: 500;
  font-size: 1.1rem;
  box-shadow: 0px 31px 55px rgba(22, 75, 217, 0.05);
  background-color: ${({ position }) =>
    position.includes("top-left") ? "#1D4ED8" : "#ffffff"};
  color: ${({ position }) =>
    position.includes("top-left") ? "#ffffff" : "#1D4ED8"};
  margin-right: ${({ position }) =>
    position.includes("bottom-left") ? "14rem" : "auto"};
  margin-top: ${({ position }) =>
    position.includes("top-right") ? "5rem" : "auto"};
  margin-bottom: ${({ position }) =>
    position.includes("top-left") ? "5rem" : "auto"};
  margin-top: ${({ position }) =>
    position.includes("bottom-left") ? "-3rem" : "auto"};
  margin-left: ${({ position }) =>
    position.includes("top-right") ? "8rem" : "auto"};
  margin-left: ${({ position }) =>
    position.includes("bottom-right") ? "14.5rem" : "auto"};
`;


const texts = [
  [
    "Capture & record your vitals",
    "Get a diagnosis",
    "Connect with doctors",
    "Track your health progress",
  ],
  [
    "Check your body temperature",
    "Get a diagnosis",
    "Connect with doctors",
    "Share your reading",
  ],
  [
    "Capture your body sounds",
    "Share your reading",
    "Connect with doctors",
    "Track your health progress",
  ],
  [
    "Get your blood pressure",
    "Get a diagnosis",
    "Connect with doctors",
    "Share your reading",
  ],
  [
    "Get your blood glucose levels",
    "Get a diagnosis",
    "Connect with doctors",
    "Share your reading",
  ],
  [
    "Get a 12 lead ECG",
    "Get a diagnosis",
    "Connect with doctors",
    "Share your reading",
  ],
];

const DesktopView = () => {
  useEffect(() => {
    AOS.init({ duration: 700 });
  }, []);

  const [currentSet, setCurrentSet] = useState(0);
  const [isLocked, setIsLocked] = useState(true);
  const timeoutRef = useRef(null);
  const [activeDot, setActiveDot] = useState(0);
  const [showText, setShowText] = useState([true, false, false, false, false]);

  const handleScrollDistance = (event) => {
    const { deltaY } = event;
    const distance = Math.abs(deltaY) / 50;

    if (distance < 1) {
      return;
    }

    clearTimeout(timeoutRef.current);

    if (currentSet === texts.length - 1 && deltaY > 0) {
      setIsLocked(false);
      return;
    } else if (currentSet === 0 && deltaY < 0) {
      return;
    }

    const newIndex = deltaY > 0 ? Math.min(currentSet + 1, texts.length - 1) : Math.max(currentSet - 1, 0);

    setCurrentSet(newIndex);

    setShowText((prevShowText) => {
      const newShowText = [...prevShowText];
      newShowText[currentSet] = false;
      newShowText[newIndex] = true;
      return newShowText;
    });


    setActiveDot((prevDot) => {
      const maxDots = texts.length;
      let newDot = prevDot + (deltaY > 0 ? 1 : -1);
      if (newDot < 0) {
        newDot = maxDots - 1;
      } else if (newDot >= maxDots) {
        newDot = 0;
      }
      return newDot;
    });


    timeoutRef.current = setTimeout(() => {
      setIsLocked(false);
    }, 3000 + distance * 2000);

    setIsLocked(true);
  };


  // useEffect(() => {
  //   const handleScroll = (event) => {
  //     if (isLocked) {
  //       event.preventDefault();
  //       handleScrollDistance(event);
  //     }
  //   };

  //   window.addEventListener("wheel", handleScroll, { passive: false });

  //   return () => {
  //     clearTimeout(timeoutRef.current);
  //     window.removeEventListener("wheel", handleScroll);
  //   };
  // }, [currentSet, isLocked]);


  const [activeIcon, setActiveIcon] = useState(1);

  const handleIconClick = (iconNumber) => {
    setActiveIcon(iconNumber);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      const nextIcon = activeIcon === 5 ? 1 : activeIcon + 1;
      setActiveIcon(nextIcon);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [activeIcon]);


  const renderMeasureTexts = () => {
    return (
      <div className="flex flex-col gap-2 w-full items-start ml-16">
        <div className="flex items-center justify-between gap-3 w-[60%]">
          <img src={measure1} alt="Blood Pressure" width={50} height={50} />
          <span>Blood pressure</span>
        </div>
        <div className="flex items-center justify-between gap-3 w-[54%]">
          <img src={measure2} alt="Body Sounds" width={40} height={40} />
          <span>Body sounds</span>
        </div>
        <div className="flex items-center justify-between gap-3 w-[66%]">
          <img src={measure3} alt="Body Temperature" width={25} height={25} />
          <span>Body temperature</span>
        </div>
        <div className="flex items-center gap-3 justify-between w-[56%]">
          <img src={measure4} alt="Blood Pressure" width={33} height={33} />
          <span>Blood glucose</span>
        </div>
        <div className="flex items-center justify-between gap-3 w-[62%]">
          <img src={measure5} alt="Body Sounds" width={48} height={48} />
          <span>ECG &#x26; heart rate</span>
        </div>
        <div className="flex items-center justify-between gap-3 w-[82%]">
          <img src={measure6} alt="Body Temperature" width={53} height={53} />
          <span>Otoscope camera for ENT</span>
        </div>
      </div>
    );
  };
  const renderAiSection = () => {
    return (
      <div
        data-aos="fade-up"
        className="flex w-[84%] mx-[8%] mt-[-4%] bg-[#F4F4F4] z-0 rounded-3xl"
      >
        <div className="flex flex-col p-14 mt-[10%] mx-[5%] mb-[5%] gap-16">
          <div>
            <ul className="flex gap-[15%]" style={{ listStyle: "disc" }}>
              <li className="text-2xl text-left text-[#041f68]">
                Patient health inference
              </li>
              <li className="text-2xl text-left text-[#041f68]">
                Detect abnormalities
              </li>
            </ul>
          </div>
          <div>
            <ul className="flex gap-[8%]" style={{ listStyle: "disc" }}>
              <li className="text-2xl w-[32%] text-left text-[#041f68]">
                Vector Search for easy summarisation and insights to health
                records
              </li>
              <li className="text-2xl text-left text-[#041f68]">
                Personalised health insight
              </li>
            </ul>
          </div>
          <div>
            <ul className="flex gap-[12%]" style={{ listStyle: "disc" }}>
              <li className="text-2xl text-left text-[#041f68]">
                Personalised health insight
              </li>
              <li className="text-2xl text-left text-[#041f68]">
                Maps clinical taxonomy for interoperability.
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };


  return (
    <>
      <Navbar />

      <div className="bg-slate-50 ">
        <div className="flex flex-col -mt-28 my-20">
          <div className="flex justify-center">
            <h1 className="mt-14 mb-16 font-medium text-5xl text-center text-[#154ad8] font-poppins">
              Get primary care at home, just like <br />an in-person visit.
            </h1>
          </div>

          <div className="flex flex-col items-center">
            <img src={ausadevice} alt="ausa_device" className="w-[90.875rem] m-auto"></img>

            {/* <TextContainer onWheel={handleScrollDistance} className="absolute">
              {texts.map((set, index) => (
                <React.Fragment key={index}>
                  {activeIcon === index + 1 && (
                    <React.Fragment>
                      {set.map((text, textIndex) => (
                        <Text
                          data-aos="zoom-in"
                          data-aos-duration="300"
                          data-aos-easing="ease-in-sine"
                          key={textIndex}
                          position={
                            textIndex === 0
                              ? "top-left"
                              : textIndex === 1
                                ? "top-right"
                                : textIndex === 2
                                  ? "bottom-left"
                                  : "bottom-right"
                          }
                          transition={{ duration: 1 }}
                        >
                          {text}
                        </Text>
                      ))}
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
            </TextContainer> */}
          </div>


          {/* <div className="flex flex-col items-center justify-center mt-6 ">
            <div className="flex gap-7 sm:gap-14">
              <div
                className={`flex flex-col items-center cursor-pointer ${activeIcon === 1 ? 'opacity-100 scale-110' : 'opacity-50'
                  }`}
                onClick={() => handleIconClick(1)}
              >
                <img src={icon1} className="w-10 h-10 sm:w-14 sm:h-14" />
              </div>

              <div
                className={`flex flex-col items-center cursor-pointer ${activeIcon === 2 ? 'opacity-100 scale-110' : 'opacity-50'
                  }`}
                onClick={() => handleIconClick(2)}
              >
                <img src={icon2} className="w-10 h-10 sm:w-14 sm:h-14" />
              </div>

              <div
                className={`flex flex-col items-center cursor-pointer ${activeIcon === 3 ? 'opacity-100 scale-110' : 'opacity-50'
                  }`}
                onClick={() => handleIconClick(3)}
              >
                <img src={icon3} className="w-10 h-10 sm:w-14 sm:h-14" />
              </div>

              <div
                className={`flex flex-col items-center cursor-pointer ${activeIcon === 4 ? 'opacity-100 scale-110' : 'opacity-50'
                  }`}
                onClick={() => handleIconClick(4)}
              >
                <img src={icon4} className="w-10 h-10 sm:w-14 sm:h-14" />
              </div>

              <div
                className={`flex flex-col items-center cursor-pointer ${activeIcon === 5 ? 'opacity-100 scale-110' : 'opacity-50'
                  }`}
                onClick={() => handleIconClick(5)}
              >
                <img src={icon5} className="w-10 h-10 sm:w-14 sm:h-14" />
              </div>
            </div>

            <div className='flex items-center gap-4 mb-12 -mt-2'>
              <div onClick={() => handleIconClick(1)} className={`w-2 h-2 bg-blue-600 rounded-full mt-4 sm:mt-10 sm:mb-4 ${activeIcon === 1 ? 'opacity-100 scale-150' : 'opacity-40'
                }`}></div>
              <div onClick={() => handleIconClick(2)} className={`w-2 h-2 bg-blue-600 rounded-full mt-4 sm:mt-10 sm:mb-4 ${activeIcon === 2 ? 'opacity-100 scale-150' : 'opacity-40'
                }`}></div>
              <div onClick={() => handleIconClick(3)} className={`w-2 h-2 bg-blue-600 rounded-full mt-4 sm:mt-10 sm:mb-4 ${activeIcon === 3 ? 'opacity-100 scale-150' : 'opacity-40'
                }`}></div>
              <div onClick={() => handleIconClick(4)} className={`w-2 h-2 bg-blue-600 rounded-full mt-4 sm:mt-10 sm:mb-4 ${activeIcon === 4 ? 'opacity-100 scale-150' : 'opacity-40'
                }`}></div>
              <div onClick={() => handleIconClick(5)} className={`w-2 h-2 bg-blue-600 rounded-full mt-4 sm:mt-10 sm:mb-4 ${activeIcon === 5 ? 'opacity-100 scale-150' : 'opacity-40'
                }`}></div>

            </div>
          </div> */}
        </div>


        <div className="relative flex flex-col mt-4 p-12 h-[48rem] bg-bluebg1 w-full bg-rep items-center">
          {/* <div className="flex mt-20 w-[70%] items-center justify-center">
            <p
              data-aos="fade-up"
              className="flex text-5xl text-center text-white font-poppins"
            >
              Why wait for care when you can have a clinic at your home.
            </p>
          </div> */}

          {/* <div className="mt-10 mb-2">
            <IconSlider />
          </div> */}

          <div className="flex flex-row w-full">
            {/* <div className="flex flex-col mt-36">
              <h1
                data-aos="fade-up"
                className="flex justify-start font-montserrat text-start text-white font-bold text-3xl mt-16 ml-4"
              >
                Powered by advanced <br />
                A.I. Technology
              </h1>

              <p
                data-aos="fade-up"
                className="flex justify-start text-2xl ml-4 mt-8 text-white font-montserrat"
              >
                Vital HUB provides personalised <br /> Health insights, anomaly
                detection <br /> and risk prediction.
              </p>
            </div> */}

            <div data-aos="fade-up" className="absolute bottom-[-16%] left-[2%] z-10">
              <img src={imgds} width={"94%"} height={"90%"}></img>
            </div>
            {/* <div
              data-aos="fade-up"
              className="absolute bottom-[-8%] left-[42%]"
            >
              <img src={measurelist} width={"85%"} height={"85%"}></img>
            </div> */}
            {/* <div
              data-aos="fade-up"
              className="absolute text-white text-center w-[20%] m-auto z-0 bottom-[4%] right-[26%] font-poppins"
            >
              <h2 className="mb-5 text-[32px]">Measure your...</h2>
              {renderMeasureTexts()}
            </div> */}

          </div>
        </div>

        <div className="flex flex-col mt-40 mb-20 font-poppins p-[2%] items-start">
          <div
            data-aos="fade-up"
            className="flex justify-center items-center z-10 relative"
          >
            <img src={ai} className="" />
            <img src={chatbot} className="absolute top-2 right-[-8%]" />
          </div>
        </div>


        <div className="flex justify-center mt-40 mb-20">
          {/* <img src={line}></img> */}
          <h1 className="absolute font-poppins font-medium text-5xl text-center text-[#041f68]">
            AUSA Vital Hub in action
          </h1>
        </div>

        <div className="flex rounded-[30px] justify-center mb-20">
          <ReactPlayer
            controls
            width="95%"
            height="853px"
            url="https://youtu.be/p3iYH6KQ29g"
            style={{
              borderRadius: "47px",
              overflow: "hidden",
            }}
          ></ReactPlayer>
        </div>


        <div className="flex flex-col items-center gap-28 bg-bluebg h-[60rem] bg-rep w-full mx-auto pt-16 overflow-hidden">
          <div className="flex flex-col  gap-28 w-[94rem]">
            {/* <img src={blueBg2}></img> */}
            <h1 className="text-5xl text-white font-poppins text-center">
              A Integrated platform to provide true value <br /> based primary care at your home
            </h1>

            <div className="flex gap-[10.813rem]">
              <p className="text-white text-3xl font-poppins ml-[14rem]">Remote patient monitoring</p>
              <p className="text-white text-3xl font-poppins ml-[10rem]">Teleconsulation</p>
            </div>

            {/* <div className="relative flex flex-col">
              <img src={spiraltop} className="w-full" />
              <img src={spiralbottom} className="absolute w-full" />
            </div> */}

            <div>
              <img src={spiralfull} />
            </div>

            <div className="flex justify-around ml-10">
              <p className="text-white text-3xl font-poppins ">Patient Engagement</p>
              <p className="text-white text-3xl font-poppins ">Health Data Analytics</p>
              <p className="text-white text-3xl font-poppins ">Preventive Care</p>
            </div>
            {/* <div className="w-full flex justify-end mr-16">
              <div
                data-aos="fade-up"
                className=" relative w-[44rem] bg-white p-8 rounded-3xl boxglow "
              >
                <p data-aos="fade-up" className="a font-primary font-bold text-xl">
                  Take charge of your well-being with our easy-to-use <br /> device
                  that measures
                </p>
              </div>
            </div> */}

            {/* <div className="w-full flex justify-start ml-16">
              <div
                data-aos="fade-up"
                className="w-[44rem] bg-white p-8 rounded-3xl boxglow"
              >
                <p data-aos="fade-up" className="font-primary font-bold   text-xl">
                  Stay connected to your health with Ausa VitalHub - <br /> The
                  ultimate telemedicine device that brings <br /> comprehensive,
                  medical-grade vital monitoring and <br /> seamless doctor
                  consultations right into your home.
                </p>
              </div>
            </div> */}

            {/* <div className="w-full flex justify-end mr-16">
              <div
                data-aos="fade-up"
                className="w-[44rem] bg-white p-8 rounded-3xl boxglow"
              >
                <p data-aos="fade-up" className=" font-primary font-bold  text-xl">
                  Ensuring you and your loved ones receive the best <br /> possible
                  care in today's fast-paced, digital world. Say <br /> hello to a
                  healthier future with VitalHub.
                </p>
              </div>
            </div> */}
          </div>
        </div>


        <div className="flex justify-center mt-16">
          <h1 className=" font-medium text-5xl text-center text-[#0c3bb7] font-poppins">
            Connect with a doctor
          </h1>
        </div>


        <div className=" flex justify-center py-24 items-center w-full overflow-hidden">
          {/* <img
            src={ellipse}
            style={{ height: 590, width: 590 }}
            className="absolute mt-24 mb-24"
          ></img> */}

          <div className="flex justify-center items-center ">
            <img
              src={doctor}
              style={{ height: 436, width: 650 }}
              className="z-20"
            ></img>

            <div className="flex flex-col z-20 gap-4">
              <div data-aos="fade-up">
                <div className="flex justify-start  gap-4 px-4 items-center w-[32rem] h-[6rem] bg-white drop-shadow-[0_31px_55px_rgba(22,75,217,0.05)] rounded-[19px]  hover:scale-105 duration-100 hover:font-bold hover:cursor-default">
                  <div className="rounded-full border-solid border-black border-[0.063rem] h-9 w-9 flex justify-center items-center">1</div>
                  <p className="text-lg font-primary">
                    Take your vitals using default test <br /> combination.
                  </p>
                </div>
              </div>

              <div data-aos="fade-up">
                <div className="flex justify-start px-4 gap-4  items-center w-[32rem] h-[6rem] bg-white drop-shadow-[0_31px_55px_rgba(22,75,217,0.05)] rounded-[19px] hover:scale-105 duration-100 hover:font-bold hover:cursor-default">
                  <div className="rounded-full border-solid border-black border-[0.063rem] h-9 w-9 flex justify-center items-center">2</div>
                  <p className="flex justify-center items-center text-lg font-primary ">
                    Book an appointment with a doctor <br /> through the device.
                  </p>
                </div>
              </div>

              <div data-aos="fade-up">
                <div className="flex justify-start px-4 gap-4  items-center w-[32rem] h-[6rem] bg-white drop-shadow-[0_31px_55px_rgba(22,75,217,0.05)] rounded-[19px] hover:scale-105 duration-100 hover:font-bold hover:cursor-default">
                  <div className="rounded-full border-solid border-black border-[0.063rem] h-9 w-9 flex justify-center items-center">3</div>
                  <p className=" flex text-lg font-primary ">
                    Share your vitals taken previously or take a test <br />{" "}
                    during the consultation.
                  </p>
                </div>
              </div>

              <div data-aos="fade-up">
                <div className="flex justify-start px-4 gap-4  items-center w-[32rem] h-[6rem] bg-white drop-shadow-[0_31px_55px_rgba(22,75,217,0.05)] rounded-[19px]  hover:scale-105 duration-100 hover:font-bold hover:cursor-default">
                  <div className="rounded-full border-solid border-black border-[0.063rem] h-9 w-9 flex justify-center items-center">4</div>
                  <p className=" flex text-lg font-primary ">
                    Get an accurate diagnosis from the doctor.
                  </p>
                </div>
              </div>
              <div data-aos="fade-up">
                {/* <div className="flex justify-start px-4 gap-0  items-center w-[32rem] h-[6rem] bg-white drop-shadow-[0_31px_55px_rgba(22,75,217,0.05)] rounded-[19px]  font-medium hover:scale-105 duration-100 hover:font-bold hover:cursor-default "> */}
                {/* <div className=""><img src={star} alt="5" className="mt-12 -ml-[4.3rem]"></img></div> */}
                <p className="pt-[2.75rem] text-2xl font-bold text-center text-[#1d2121]">
                  All at the convenience of your home :)
                </p>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>



        <div className="footer-container text-white bg-black flex justify-center overflow-x-hidden pt-8 font-primary sm:text-xl md:text-2xl ">
          <div className="flex flex-col items-center">
            <img
              src={ausalogowhite}
              className="w-[15rem] justify-center"
            ></img>
            <p
              className="mb-4 w-[25rem]"
              // src={footerlogo}
              alt="Ausa Health"
            ></p>
            <p className="text-gray-500 mb-4 flex justify-center text-lg">By</p>
            <div className="justify-center mb-8">
  <h1 style={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}>
    Prar, Inc.
  </h1>
</div>
            {/* <p className="text-gray-500 mb-7 flex justify-center text-lg">Prar Labs Private Limtied</p> */}


            {/* <p className="text-gray-400 mb-4 flex justify-center text-lg">700/2 Modi hospital compound, Bengaluru - 560086</p>
            <p className="text-gray-400 mb-4 flex justify-center text-lg">CIN: U73100KA2023PTC170052</p>
            <p className="text-gray-400 mb-4 flex justify-center text-lg">https://ausa.health/</p> */}


            <p className="text-gray-400 mb-4 font-manrope flex justify-center text-lg">
              Get in touch...
            </p>

            <div className="flex items-center gap-2 justify-center font-primary mb-20 text-lg ">
              <img className="h-4" src={mail}></img>
              <h4 className="font-manrope text-2xl">hello@ausa.health</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};



const MobileView = () => {
  useEffect(() => {
    AOS.init({ duration: 700 });
  }, []);

  // const renderMeasureTexts = () => {
  //   return (
  //     <div className="flex flex-col gap-2 w-full items-start ml-2">
  //       <div className="flex items-center justify-between gap-3">
  //         <img
  //           className="w-10 h-10 sm:w-5 sm:h-5"
  //           src={measure1}
  //           alt="Blood Pressure"
  //         />
  //         <span className="text-[6px]">Blood pressure</span>
  //       </div>
  //       <div className="flex items-center justify-between gap-3">
  //         <img
  //           className="w-10 h-10 sm:w-5 sm:h-5"
  //           src={measure2}
  //           alt="Body Sounds"
  //         />
  //         <span className="text-[6px]">Body sounds</span>
  //       </div>
  //       <div className="flex items-center justify-between gap-3">
  //         <img
  //           className="w-10 h-10 sm:w-5 sm:h-5"
  //           src={measure3}
  //           alt="Body Temperature"
  //         />
  //         <span className="text-[6px]">Body temperature</span>
  //       </div>
  //       <div className="flex items-center gap-3 justify-between">
  //         <img
  //           className="w-10 h-10 sm:w-5 sm:h-5"
  //           src={measure4}
  //           alt="Blood Pressure"
  //         />
  //         <span className="text-[6px]">Blood glucose</span>
  //       </div>
  //       <div className="flex items-center justify-between gap-3">
  //         <img
  //           className="w-10 h-10 sm:w-5 sm:h-5"
  //           src={measure5}
  //           alt="Body Sounds"
  //         />
  //         <span className="text-[6px]">ECG &#x26; heart rate</span>
  //       </div>
  //       <div className="flex items-center justify-between gap-3">
  //         <img
  //           className="w-10 h-10 sm:w-5 sm:h-5"
  //           src={measure6}
  //           alt="Body Temperature"
  //         />
  //         <span className="text-[6px]">Otoscope camera for ENT</span>
  //       </div>
  //     </div>
  //   );
  // };
  // const renderAiSection = () => {
  //   return (
  //     <div
  //       data-aos="fade-up"
  //       className="flex w-[84%] mx-[8%] mt-[-4%] bg-[#F4F4F4] z-0 rounded-3xl"
  //     >
  //       <div className="flex flex-col p-4 mt-[10%] mx-[5%] mb-[5%] gap-8">
  //         <div>
  //           <ul className="gap-[15%]" style={{ listStyle: "disc" }}>
  //             <li className="text-lg text-left text-[#041f68]">
  //               Patient health inference
  //             </li>
  //             <li className="text-lg text-left text-[#041f68]">
  //               Detect abnormalities
  //             </li>
  //           </ul>
  //         </div>
  //         <div>
  //           <ul className="gap-[8%]" style={{ listStyle: "disc" }}>
  //             <li className="text-lg w-full text-left text-[#041f68]">
  //               Vector Search for easy summarisation and insights to health
  //               records
  //             </li>
  //             <li className="text-lg text-left text-[#041f68]">
  //               Personalised health insight
  //             </li>
  //           </ul>
  //         </div>
  //         <div>
  //           <ul className="gap-[12%]" style={{ listStyle: "disc" }}>
  //             <li className="text-lg text-left text-[#041f68]">
  //               Personalised health insight
  //             </li>
  //             <li className="text-lg text-left text-[#041f68]">
  //               Maps clinical taxonomy for interoperability.
  //             </li>
  //           </ul>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    // <div className='h-full flex flex-col px-12  justify-around mt-24  sm:mt-0 sm:justify-between items-center'>
    //    <h2 className='text-center text-3xl font-montserrat font-medium mt-4'>Please view in desktop</h2>
    //    <img className='sm:h-[26rem] sm:w-[26rem]  mt-20' src={trafficcone}></img>
    //    <h2 className='text-center text-xl font-primary font-light'><span className='font-bold'>ausa.health </span>page is under construction</h2>
    //    <img className='sm:max-h-[30rem] sm:max-w-[30rem] -mt-5'src={ausalogo}></img>
    // </div>

    <>
      <Navbar />

      <div className="mt-24 sm:text-2xl relative bg-slate-50">
        <div className="flex flex-col justify-center items-center -mt-8 sm:-mt-28">
          <h1 className="font-poppins text-center text-blue-600 font-medium sm:text-3xl text-2xl mx-4 mt-8">
            Get primary care at home, just like an in-person visit.
          </h1>

          <img src={ausadevice} className=" h-auto mt-10 px-2" />

          {/* <div> */}
            {/* <LandingSlider /> */}
          {/* </div> */}

          <div className="relative flex flex-col mt-4 h-full bg-bluebg1 w-full bg-rep  justify-center items-center">
            {/* <div className="flex mt-20 w-[70%] items-center justify-center">
              <p
                data-aos="fade-up"
                className="flex text-2xl text-center text-white font-poppins"
              >
                Why wait for care when you can have a clinic at your home.
              </p>
            </div> */}

            {/* <div className="flex flex-row w-full"> */}
              {/* <div className="absolute bottom-[-15%] h-full w-full left-[2%] z-10"> */}
                <img src={imgop} ></img>
              {/* </div> */}
              {/* <div
                data-aos="fade-up"
                className="absoute bottom-[-4%] items-center "
              >
                <img src={measurelist} width={"50%"} height={"50%"}></img>
              </div> */}
              {/* <div
                data-aos="fade-up"
                className="absolute text-white sm:w-[20%] sm:h-[20%] text-center w-[20%] z-0 bottom-[4%] right-[26%] sm:bottom-[-5%] sm:right-[20%] font-poppins"
              > */}
                {/* <h2 className="text-lg sm:mb-1 sm:text-md">Measure your...</h2>
                {renderMeasureTexts()} */}
              {/* </div> */}
            {/* </div> */}
          </div>

          <div className="flex flex-col mt-10 font-poppins p-[2%] items-start">
            <div
              data-aos="fade-up"
              className="flex justify-center gap-8 items-center z-10 relative"
            >
              <img src={ai} className="" />
              <img src={chatbot} className="absolute top-2 right-[-2%] w-[45%]" />
            </div>
            {/* <img src={chatbot} className="absolute top-2 right-[-8%]"/> */}
            {/* {renderAiSection()} */}
          </div>
          {/* <div className="">
            <FloatingChatbotButton />
          </div> */}

          <div className="flex flex-col h-auto justify-center mt-5">
            <h1 className="font-poppins text-center text-[#041f68] text-2xl mx-4 my-2 sm:text-4xl sm:mt-10">
              AUSA Vital Hub in action
            </h1>

            <div className="flex justify-center mt-4">
              <ReactPlayer
                controls
                width="90vw"
                height="50vw"
                url="https://youtu.be/p3iYH6KQ29g"
                style={{
                  borderRadius: "20px",
                  overflow: "hidden",
                }}
              ></ReactPlayer>
            </div>
          </div>

          <div className="flex flex-col items-center gap-8 mt-16 h-[22rem] bg-bluebg1 w-full bg-rep">
            {/* <h1 className="flex justify-start font-poppins text-start text-white text-2xl mt-12 m-6 mb-0">
              A Integrated platform to provide true value based primary care at your home
            </h1>

            <div data-aos="fade-up" className="bg-white rounded-tr-3xl rounded-br-3xl drop-shadow-[0_31px_55px_rgba(255, 255, 255, 0.21)] mr-16 mt-10">
              <p className="text-black p-6 font-bold text-lg font-primary">Take charge of your well-being with our easy-to-use device that measures</p>
            </div>

            <div data-aos="fade-up" className="justify-end bg-white rounded-tl-3xl rounded-bl-3xl drop-shadow-[0_31px_55px_rgba(255, 255, 255, 0.21)] ml-16 mt-6">
              <p className="text-black p-6 font-bold text-lg font-primary">Stay connected to your health with Ausa VitalHub -
                The ultimate telemedicine device that brings comprehensive, medical-grade vital monitoring and seamless doctor consultations right into your home.</p>
            </div>

            <div data-aos="fade-up" className="bg-white rounded-tr-3xl rounded-br-3xl drop-shadow-[0_31px_55px_rgba(255, 255, 255, 0.21)] mr-16 mt-6">
              <p className="text-black p-6 font-bold text-lg font-primary">Ensuring you and your loved ones receive the best possible care in today's fast-paced, digital world. Say hello to a healthier future with VitalHub.</p>
            </div> */}

            <h1 className="my-6 text-md text-white font-poppins text-center">
              A Integrated platform to provide true value <br /> based primary care at your home
            </h1>
            <div className="flex flex-col gap-2 items-center">
              <div className="flex gap-5">
                <p className="text-white text-sm text-center font-poppins">Remote patient monitoring</p>
                <p className="text-white text-sm text-center font-poppins">Teleconsultation</p>
              </div>

              <div>
                <img src={spiralfull} />
              </div>

              <div className="flex justify-around">
                <p className="text-white text-sm text-center font-poppins">Patient Engagement</p>
                <p className="text-white text-sm text-center font-poppins">Health Data Analytics</p>
                <p className="text-white text-sm text-center font-poppins">Preventive Care</p>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-10">
            <h1 className="font-poppins text-center text-[#0C3BB7] text-2xl mx-4 my-2 sm:text-4xl z-10 md:mb-32 sm:mb-20">
              Connect with a doctor
            </h1>

            {/* <div className="flex justify-center items-center mt-8 overflow-hidden">
              <img
                src={ellipse}
                style={{ width: '115%', maxWidth: 'none', height: 'auto', }}
              ></img>
            </div> */}


            <div className="flex justify-center items-center">
              <img
                src={doctor}
                style={{}}
                className="rounded-2xl z-20 "
              ></img>
            </div>

            <div className="z-30">
              <TextSlider />
            </div>
          </div>

          <div className="footer-container text-white overflow-x-hidden min-w-full bg-black flex justify-center pt-8 mt-10 font-primary sm:text-xl md:text-2xl ">
            <div className="flex flex-col">
              <img
                src={ausalogowhite}
                className="w-[15rem] justify-center ml-10"
              ></img>
              <p className="text-gray-500 mb-7 flex justify-center text-lg">By</p>
              <img
                className="mb-10 w-[20rem]"
                src={footerlogo}
                alt="Prar, Inc."
              ></img>
              <p className="text-gray-400 mb-4 flex justify-center text-lg">
                Get in touch...
              </p>

              <div className="flex items-center gap-2 justify-center font-primary mb-20 text-lg ">
                <img className="h-4" src={mail}></img>
                <h4>hello@ausa.health</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


const Ausa = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });

  return (
    <div>
      {isMobile ? <MobileView /> : <DesktopView />}
    </div>

  )
}

export default Ausa;