import React, { useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import ausa_logo from "../assets/ausaLogo.png";

const Navbar = () => {
  const [hamclicked, sethamclicked] = useState(true);

  return (
    <nav className="flex flex-col sm:mb-[12rem]  bg-white">
      <div className={hamclicked ? "navbar-white" : "navbar-black"}>

        <div>
          <img className="sm:w-56 sm:mt-8 mt-5 w-32 sm:-ml-1 -ml-8" src={ausa_logo}></img>
        </div>

        {/* <div className="flex gap-[6.25rem] sm:gap-4">
          <p className="font-montserrat sm:text-lg text-sm sm:mr-10 -mr-4"><a href="/product" target="_blank">Product</a> </p>
          <p className="font-montserrat sm:text-lg text-sm sm:mr-10 -mr-4"><a href="/tech" target="_blank">Tech</a> </p>
          <p className="font-montserrat sm:text-lg text-sm sm:mr-10 -mr-4"><a href="/team" target="_blank">Team</a> </p>
          <p className="font-montserrat sm:text-lg text-sm sm:mr-10 -mr-4"><a href="/login" target="_blank">Login</a> </p>
        </div> */}
        <p className="font-montserrat sm:text-lg text-sm sm:mr-10 -mr-4"><a href="https://calendar.notion.so/meet/mallikarjun/6e363c4h" target="_blank">Connect</a> </p>
      </div>
    </nav>
  );
};

export default Navbar;
